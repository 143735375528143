export const homeAndFormTexts = {
    formSection: {
        name: "Nombre *",
        phone: "Teléfono *",
        email: "Correo *",
        message: "Si tienes dudas escríbenos aquí tu consulta ...",
        rgpdText: "Acepto el tratamiento de datos y políticas de privacidad de Thinkia (Search Engine Business S.L.).*",
        informationButton: "Quiero más información"
    }
};
export const sectionTwoTexts = {
    mainSection: {
        title: {
            firstPart: "Aprende de la mano de destacados profesionales del sector, en activo y con contrastada experiencia docente.",
            secondPart: "Adquiere nuevos conocimientos y las habilidades que demandan las empresas."
        },
        stats: [
            {
                number: "9",
                label: "SEMANAS"
            },
            {
                number: "90",
                label: "HORAS"
            },
            {
                number: "8",
                label: "DOCENTES EXPERTOS"
            }
        ]
    },
    learnSection: {
        title: "¿Qué vas a conseguir?",
        benefits: [
            {
                text: "Comprender los tipos de datos, estructuras básicas, control de flujo y funciones para desarrollar una base sólida en programación con Python."
            },
            {
                text: "Adquirir conocimientos sobre clases, objetos, atributos y conceptos avanzados como herencia y polimorfismo para aplicar POO en proyectos."
            },
            {
                text: "Profundizar en estructuras avanzadas y colecciones especializadas para optimizar la organización y acceso a datos complejos."
            },
            {
                text: "Manejar archivos, gestionar errores y realizar procesamiento de datos con pandas y numpy, enfocándose en análisis y transformación de datos."
            },
            {
                text: "Aplicar y crear decoradores y generadores para realizar programación funcional avanzada y eficiente en Python."
            },
            {
                text: "Desarrollar habilidades en extracción de datos web utilizando herramientas como BeautifulSoup, Requests y Selenium para obtener información de diversas fuentes online."
            },
            {
                text: "Construir y desplegar aplicaciones web y APIs, familiarizándose con frameworks como Flask y FastAPI, así como con la dockerización de entornos."
            },
            {
                text: "Implementar pruebas unitarias y técnicas de optimización para asegurar la calidad y eficiencia del código."
            }
        ]
        
    }
};

export const accordionTexts = {
    title: "Contenido del Máster",
    modules: [
        {
            number: 'Módulo 01',
            title: 'Fundamentos de Python',
            content: [
                {
                    list: [
                        'Tipos de datos: variables, operadores y estructuras básicas (listas, tuplas, sets, diccionarios).',
                        'Control de flujo: condicionales y bucles.',
                        'Funciones y módulos.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 02',
            title: 'Programación Orientada a Objetos (POO)',
            content: [
                {
                    list: [
                        'Clases, objetos, atributos y métodos.',
                        'Herencia, polimorfismo y encapsulamiento.',
                    ]
                }
            ]
        },
        {
            number: 'Módulo 03',
            title: 'Estructuras de Datos Avanzadas',
            content: [
                {
                    list: [
                        'Listas, tuplas, sets y diccionarios.',
                        'Colecciones avanzadas: ChainMap, OrderedDict, defaultdict, Counter, enum.',
                    ]
                }
            ]
        },
        {
            number: 'Módulo 04',
            title: 'Manipulación de Datos',
            content: [
                {
                    list: [
                        'Lectura y escritura de archivos.',
                        'Manejo de errores y excepciones.',
                        'Introducción a pandas y numpy para procesamiento de datos.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 05',
            title: 'Decoradores y Generadores',
            content: [
                {
                    list: [
                        'Funciones de orden superior.',
                        'Uso y creación de decoradores.',
                        'Generadores con yield.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 06',
            title: 'Web Scraping',
            content: [
                {
                    list: [
                        'Uso de BeautifulSoup, Requests y Selenium.',
                        'Ejemplos prácticos de extracción de datos.',
                    ]
                }
            ]
        },
        {
            number: 'Módulo 07',
            title: 'Desarrollo Web con Flask y APIs',
            content: [
                {
                    list: [
                        'Introducción a Flask.',
                        'Introducción APIs REST.',
                        'Creación API con FastAPI.',
                        'Dockerización de aplicaciones.',
                    ]
                }
            ]
        },
        {
            number: 'Módulo 08',
            title: 'Pruebas y Optimización de Código',
            content: [
                {
                    list: [
                        'Pruebas unitarias con unittest o pytest.',
                        'Optimización y profiling de código.',
                    ]
                }
            ]
        },
        {
            number: 'Módulo 09',
            title: 'Proyecto Final',
            content: [
                {
                    description: 'Creación de un proyecto con una API creada con Flask o FastAPI que consuma datos de un LLM y servidos en un frontend con Bootstrap.',
                },
            ]
        }
    ]
};


export const targetAudienceTexts = {
    mainTitle: "¿A quién va dirigido?",
    prerequisites: {
        description: "Orientado a desarrolladores de software que buscan adquirir conocimientos en Python, o para perfiles que ya han programado en Python buscan mejorar sus capacidades o refrescar conceptos en este lenguaje.",
    },
    features: [
        {
            iconAlt: "Icono casa",
            text: "Desde Casa"
        },
        {
            iconAlt: "Icono Másterclass",
            text: "Másterclasses en directo"
        },
        {
            iconAlt: "Icono expertos",
            text: "Profesionales expertos en activo"
        }
    ]
};
export const upcomingSectionTexts = {
    mainTitle: "Próximas Convocatorias",
    edition: {
        items: [
            {
                text: "I Edición"
            },
            {
                text: "14 Marzo 2025"
            },
            {
                text: "Masterclass en directo\nViernes y Sábados"
            },
            {
                text: "Vie. 15:00 a 20:00h\nSáb. 9:00 a 14:00h"
            }
        ]
    },
    pricing: {
        label: "Precio",
        amount: "3.200€",
        buttonText: "Quiero más información"
    },
    details: {
        title: "Detalles del Máster",
        list: [
            "9 semanas - 90 horas",
            "8 docentes en activo",
            "Certificación al finalizar",
            "Masterclass en directo"
        ]
    }
};
export const faqSectionTexts = {
    title: "Preguntas Frecuentes",
    questions: [
        {
            question: '¿La formación es en directo?',
            answer: 'Sí, hemos ideado nuestro Programa Ingeniero Backend Python como una formación completamente online pero sin perder la esencia de poder vivirla compartiendo la experiencia en directo con tus compañeros y profesores. Así pues, cada clase será en directo para que puedas preguntar tus dudas a tus profesores y hablar con tus compañeros.'
        },
        {
            question: '¿Cómo puedo acceder al Programa?',
            answer: 'Es tan fácil como completar la solicitud con tus datos y nos pondremos rápidamente en contacto contigo. Agendaremos una entrevista para conocer tus intereses y ayudarte a alcanzar tu objetivo.'
        },
        {
            question: '¿Qué requisitos hacen falta?',
            answer: 'Partirás con una base si tienes conocimiento sobre producto, desarrollo, data, marketing o negocio, pero no son requerimientos obligatorios.'
        },
        {
            question: '¿Por qué elegir este Programa y no otro?',
            answer: 'En Thinkia Training hemos creado un programa 100% online con Masterclass en directo cada viernes y sábado en el que adquirás de la mano de profesionales en activo que tienen experiencia con este rol, y estarás en contacto con compañeros en un punto profesional similar al tuyo, y con las mismas inquietudes. Estamos totalmente comprometidos con tu formación, por eso hemos diseñado un programa adaptado a las necesidades actuales de las empresas con profesores que son profesionales de alto nivel en activo.'
        },
        {
            question: '¿Lo puedo hacer mientras estoy trabajando?',
            answer: 'El horario está adaptado al horario laboral para que puedas compaginar tu trabajo con tu formación. Además, al tratarse de una formación online puedes conectarte desde cualquier lugar a la hora de la Masterclass y pondremos a tu disposición la documentación que aporte cada profesor en nuestra plataforma formativa. Y si algún día no puedes conectarte las sesiones se grabarán para verlas luego.'
        }
    ]
};

const textContextData = {
    homeAndFormTexts,
    sectionTwoTexts,
    accordionTexts,
    targetAudienceTexts,
    upcomingSectionTexts,
    faqSectionTexts
};

export default textContextData;