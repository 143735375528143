import React from "react";

const QuienesSomos = () => {
  return (
    <div className="container pt-md-140 pt-40 pb-140" id="quienes-somos">
      <p
        style={{
          color: "#B5B5B5",
          fontWeight: 400,
          paddingBottom: "20px",
          fontSize: "22px",
        }}
      >
        Quiénes somos
      </p>
      <div className="row">
        <div className="col-md-4 text-start text-md-start">
          {/* Primera columna */}
          <div>
            <h2 className="fw-bolder pb-4">Nuestra Misión</h2>
            <p
              className="pr-md-80"
              style={{
                color: "#1A224C",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: 400
              }}
            >
              Nos enfocamos en hacer realidad la visión de nuestros clientes a
              través de la innovación. <br />
              <br /> Proporcionamos asesoramiento estratégico, soluciones
              prácticas y una integración eficiente de tecnologías, como la
              inteligencia artificial, para optimizar procesos y brindar una
              gran ventaja competitiva.
              <br />
              <br /> Construimos relaciones a largo plazo con nuestros clientes,
              basadas en la confianza y el éxito mutuo.
            </p>
          </div>
        </div>
        <div className="col-md-4 text-center px-5 pt-40">
          {/* Segunda columna */}
          <img src="assets/img/logo/raya.svg" alt="Imagen" />
        </div>
        <div
          className="col-md-4 d-md-flex flex-column justify-content-md-end text-start text-md-start pt-md-300 pt-100"
        >
          {/* Tercera columna */}
          <div>
            <h2 className="fw-bolder pb-4">Nuestra Visión</h2>
            <p
              style={{
                color: "#1A224C",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "130%",
              }}
            >
              Somos un equipo de expertos desarrollando estrategias basadas en
              la tecnología. <br />
              <br />
              No esforzamos día a día por ser reconocidos como los pioneros en
              soluciones innovadoras y líderes en la implementación de
              diferentes tecnologías para mejorar la productividad y el impacto
              de nuestros clientes dentro del mercado.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuienesSomos;
