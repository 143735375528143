import React, { useState } from "react";
import { db } from "src/utilities/firebase.config";
import { collection, addDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/FormLandingProductManagement.css";
import "../../assets/css/HeaderLandingProductManagement.css";
import LogoThinkia from "../../assets/logos/LogoThinkiaTraining.png";
import HamburgerMenu from "./HamburguerMenu";
import { homeAndFormTexts } from "../../data/textContext";

const SectionOne = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    nombre: "",
    telefono: "",
    correo: "",
    consulta: "",
    acepto: false,
  });

  const [validated, setValidated] = useState(false); // Estado para la validación

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true); // Marcar el formulario como validado para mostrar los errores
    } else {
      try {
        setIsLoading(true);

        // Añadir el campo curso
        const dataToSubmit = {
          ...formData,
          curso: "Programa Avanzado en Backend Python",
          timestamp: new Date(),
        };

        // Agregar el documento a Firestore
        await addDoc(
          collection(db, "formularios"),
          dataToSubmit
        );

        // Resetear el formulario
        setFormData({
          nombre: "",
          telefono: "",
          correo: "",
          consulta: "",
          acepto: false,
        });
        form.classList.remove("was-validated");

        // Restablecer la validación para eliminar los errores
        setValidated(false);

        // Mostrar mensaje de éxito en el Toast
        toast.success("👍 Formulario enviado con éxito", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
        });
      } catch (error) {
        console.error("Error al guardar el documento:", error);

        // Mostrar mensaje de advertencia en el Toast en caso de error
        toast.warning(
          "❗ Hubo un problema al enviar el formulario. Por favor, inténtalo de nuevo.",
          {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: true,
          }
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <section className="landing-product-management" id="home">
      <div className="row">
        <div className="col-12 col-md-4 d-flex d-md-none justify-content-between align-items-center mobile-header px-3">
          <img src={LogoThinkia} alt="Logotipo" className="logo-landing-product-management" />
          <HamburgerMenu />
        </div>

        <div className="col-md-8 header-landing-product-managementBP d-flex align-items-center justify-content-center">
          <div className="overlay-landing-product-management"></div>
          <div className="content-landing-product-management text-white">
            <div className="d-flex align-items-center d-none d-md-flex mb-3">
              <img src={LogoThinkia} alt="logo" className="logo-landing-product-management me-2" />
            </div>
            <h1 className="title-landing-product-management">Programa Ingeniero<br /> <span><strong>Backend Python</strong></span></h1>
          </div>
        </div>

        <div className="col-md-4 form-wrapper-landing-product-management d-flex align-items-center justify-content-center">
        <form
            className="needs-validation form-landing-product-management d-flex flex-column justify-content-between gap-3"
            noValidate
            onSubmit={handleSubmit}
            validated={validated.toString()}  // Añadimos la propiedad validated al formulario
          >
            <h3 className="title-form-landing-product-management">Solicita más información sin compromiso</h3>
            <input type="hidden" name="curso" value="Master en Ciberseguridad" />
            <div className="input-landing-product-management-container">
            <input
                type="text"
                name="nombre"
                className={`input-landing-product-management ${validated && formData.nombre ? 'is-valid' : (validated ? 'is-invalid' : '')}`}
                placeholder={homeAndFormTexts.formSection.name}
                value={formData.nombre}
                onChange={handleChange}
                required
              />
              <div className="invalid-feedback">Por favor, ingresa un nombre válido.</div>
            </div>
            <div className="input-landing-product-management-container">
            <input
                type="tel"
                name="telefono"
                className={`input-landing-product-management ${validated && formData.telefono ? 'is-valid' : (validated ? 'is-invalid' : '')}`}
                placeholder={homeAndFormTexts.formSection.phone}
                value={formData.telefono}
                onChange={handleChange}
                required
              />
              <div className="invalid-feedback">Por favor, ingresa un teléfono válido.</div>
            </div>
            <div className="input-landing-product-management-container">
            <input
                type="email"
                name="correo"
                className={`input-landing-product-management ${validated && formData.correo ? 'is-valid' : (validated ? 'is-invalid' : '')}`}
                placeholder={homeAndFormTexts.formSection.email}
                value={formData.correo}
                onChange={handleChange}
                required
              />
              <div className="invalid-feedback">Por favor, ingresa un correo electrónico válido.</div>
            </div>
            <div className="input-landing-product-management-container">
            <textarea
                name="consulta"
                className="textarea-landing-product-management"
                placeholder={homeAndFormTexts.formSection.message}
                value={formData.consulta}
                onChange={handleChange}
              />
            </div>
            <div className="form-check form-check-container-landing-product d-flex">
            <input
                type="checkbox"
                name="acepto"
                className="form-check-input custom-checkbox-landing-product-management align-self-start mt-1"
                id="acceptance-checkbox-landing-product-management"
                checked={formData.acepto}
                onChange={handleChange}
                required
              />
              <div className="d-flex flex-column">
                <label className="form-check-label ms-3" htmlFor="acceptance-checkbox-landing-product-management">{homeAndFormTexts.formSection.rgpdText}
                </label>
                <div className="invalid-feedback">Debes aceptar los términos para continuar.</div>
              </div>
            </div>
            <div>
              <button type="submit" className="btn-landing-product-management mt-4">
                <p>{homeAndFormTexts.formSection.informationButton}</p>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M5.33012 13.6701L4.26946 12.6094L10.6334 6.24546L4.79979 6.24546L4.79979 4.73932H13.2002V13.1398L11.6941 13.1398L11.6941 7.30612L5.33012 13.6701Z" fill="white" />
                  </svg>
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* Contenedor para los toasts */}
      <ToastContainer />
    </section>
  );
};

export default SectionOne;
