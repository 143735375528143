import React, { useState } from 'react';
import "../../assets/css/common_ccs/AnimatedDownloadButton.css"

const Spinner = () => (
    <div className="inline-block ml-2">
      <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
    </div>
  );
  
  const AnimatedDownloadButton = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
  
    const handleDownload = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        // Simulamos un tiempo de carga con setTimeout
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        const pdfUrl = new URL(
          '../../docs/Thinkia_Training_Programa_Ingeniero_Backend_Data_Science_Python.pdf', 
          import.meta.url
        ).href;
        
        const response = await fetch(pdfUrl);
        
        if (!response.ok) {
          throw new Error('No se pudo acceder al archivo');
        }
        
        const blob = await response.blob();
        const fileUrl = window.URL.createObjectURL(blob);
        
        // Esperamos un segundo más antes de iniciar la descarga
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'Thinkia_Training_Programa_Ingeniero_Backend_Data_Science_Python.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        window.URL.revokeObjectURL(fileUrl);

        await new Promise(resolve => setTimeout(resolve, 1000));
        
      } catch (error) {
        console.error('Error al descargar:', error);
        setError('No se pudo descargar el archivo. Por favor, verifica la ruta del archivo.');
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <div className="text-center mt-4 accordion-button-download-container">
        <div className="btn-3-wrapper">
          <button
            className={`btn-download-animated-test ${isLoading ? 'disabled' : ''}`}
            onClick={handleDownload}
            disabled={isLoading}
            style={{ minWidth: '200px', position: 'relative' }}
          >
            <span className="texto" style={{ 
              opacity: isLoading ? '0.8' : '1',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px'
            }}>
              {isLoading ? (
                <>
                  Descargando...
                  <Spinner />
                </>
              ) : (
                'Descarga el temario'
              )}
            </span>
          </button>
        </div>
        {error && (
          <p className="text-red-500 text-sm mt-2">{error}</p>
        )}
      </div>
    );
  };
  
  export default AnimatedDownloadButton;

