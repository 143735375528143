export const homeAndFormTexts = {
    formSection: {
        name: "Nombre *",
        phone: "Teléfono *",
        email: "Correo *",
        message: "Si tienes dudas escríbenos aquí tu consulta ...",
        rgpdText: "Acepto el tratamiento de datos y políticas de privacidad de Thinkia (Search Engine Business S.L.).*",
        informationButton: "Quiero más información"
    }
};
export const sectionTwoTexts = {
    mainSection: {
        title: {
            firstPart: "Aprende de la mano de destacados profesionales del sector, en activo y con contrastada experiencia docente.",
            secondPart: "Adquiere nuevos conocimientos y las habilidades que demandan las empresas."
        },
        stats: [
            {
                number: "12",
                label: "SEMANAS"
            },
            {
                number: "120",
                label: "HORAS"
            },
            {
                number: "9",
                label: "DOCENTES EXPERTOS"
            }
        ]
    },
    learnSection: {
        title: "¿Qué vas a conseguir?",
        benefits: [
            {
                text: "Adquirir conocimientos esenciales en tipos de datos, control de flujo y creación de funciones, módulos y paquetes en Python para aplicaciones en Data Science."
            },
            {
                text: "Desarrollar habilidades para leer, escribir y manipular datos usando pandas, numpy y gestionar excepciones en Python."
            },
            {
                text: "Aprender a representar datos visualmente mediante gráficos básicos y avanzados utilizando matplotlib y seaborn."
            },
            {
                text: "Aplicar conceptos de estadística descriptiva y realizar análisis de correlación y visualización de distribuciones de datos."
            },
            {
                text: "Adquirir técnicas de limpieza de datos, manejo de valores faltantes, y codificación de datos categóricos para preparar conjuntos de datos."
            },
            {
                text: "Comprender los principios de machine learning, aplicando algoritmos de clasificación, regresión, clustering y evaluando modelos con métricas y validación cruzada."
            },
            {
                text: "Explorar modelos avanzados como árboles de decisión y técnicas de ensamblado (bagging, boosting) junto con el análisis de series temporales."
            },
            {
                text: "Iniciarse en el procesamiento de texto con NLP, incluyendo análisis y extracción de características con herramientas como nltk y spaCy."
            },
            {
                text: "Introducirse en redes neuronales con TensorFlow y Keras, aplicando ANN, CNN y RNN en distintos tipos de datos."
            }
        ]
    }
};

export const accordionTexts = {
    title: "Contenido del Máster",
    modules: [
        {
            number: 'Módulo 01',
            title: 'Fundamentos de Python para Data Science',
            content: [
                {
                    list: [
                        'Tipos de datos esenciales: listas, tuplas, sets, diccionarios.',
                        'Control de flujo: condicionales y bucles.',
                        'Funciones, módulos y manejo de paquetes con pip.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 02',
            title: 'Manipulación de Datos',
            content: [
                {
                    list: [
                        'Lectura y escritura de archivos (CSV, JSON, Excel).',
                        'Manejo de errores y excepciones.',
                        'Manipulación de datos con pandas.',
                        'Operaciones con matrices y álgebra lineal usando numpy.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 03',
            title: 'Visualización de Datos',
            content: [
                {
                    list: [
                        'Introducción a la visualización con matplotlib y seaborn.',
                        'Creación de gráficos básicos (líneas, barras, histogramas, dispersión).',
                        'Gráficos avanzados (mapas de calor, visualización de datos categóricos).'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 04',
            title: 'Análisis Estadístico',
            content: [
                {
                    list: [
                        'Conceptos básicos de estadística descriptiva (media, mediana, varianza).',
                        'Visualización y distribución de datos.',
                        'Pruebas estadísticas y correlación.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 05',
            title: 'Limpieza y Preparación de Datos',
            content: [
                {
                    list: [
                        'Detección y manejo de valores faltantes.',
                        'Normalización, escalado y estandarización de datos.',
                        'Tratamiento de datos categóricos: codificación (OneHotEncoder, LabelEncoder).'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 06',
            title: 'Machine Learning',
            content: [
                {
                    list: [
                        'Introducción a scikit-learn y flujo de trabajo de machine learning.',
                        'Algoritmos de clasificación: Regresión logística, K-Nearest Neighbors, Support Vector Machines.',
                        'Algoritmos de regresión: Regresión Lineal, Polinómica.',
                        'Algoritmos de clustering: K-Means, DBSCAN.',
                        'Evaluación de modelos: métricas, validación cruzada y ajuste de hiperparámetros.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 07',
            title: 'Técnicas Avanzadas de Machine Learning',
            content: [
                {
                    list: [
                        'Bosques aleatorios (Random Forest) y árboles de decisión.',
                        'Ensamblado de modelos: Bagging, Boosting (Gradient Boosting, XGBoost).',
                        'Modelos de series temporales con statsmodels.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 08',
            title: 'Procesamiento de Datos No Estructurados',
            content: [
                {
                    list: [
                        'Introducción al procesamiento de texto (NLP).',
                        'Análisis de texto con nltk o spaCy.',
                        'Extracción de características con TF-IDF y Word2Vec.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 09',
            title: 'Deep Learning',
            content: [
                {
                    list: [
                        'Introducción a TensorFlow y Keras.',
                        'Redes neuronales artificiales (ANN).',
                        'Redes neuronales convolucionales (CNN) para imágenes.',
                        'Redes neuronales recurrentes (RNN) para secuencias y series temporales.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 10',
            title: 'Proyecto Final',
            content: [
                {
                    description: 'Desarrollo de un proyecto completo de Data Science, desde la recolección y preparación de datos, pasando por la modelización y evaluación de resultados.',
                },
                {
                    description: 'Implementación de un modelo en un entorno de producción con Docker.'
                }
            ]
        }
    ]
};


export const targetAudienceTexts = {
    mainTitle: "¿A quién va dirigido?",
    prerequisites: {
        description: "Orientado a perfiles que buscan introducirse en el mundo de Data Science.",
    },
    features: [
        {
            iconAlt: "Icono casa",
            text: "Desde Casa"
        },
        {
            iconAlt: "Icono Másterclass",
            text: "Másterclasses en directo"
        },
        {
            iconAlt: "Icono expertos",
            text: "Profesionales expertos en activo"
        }
    ]
};
export const upcomingSectionTexts = {
    mainTitle: "Próximas Convocatorias",
    edition: {
        items: [
            {
                text: "I Edición"
            },
            {
                text: "21 Marzo 2025"
            },
            {
                text: "Masterclass en directo\nViernes y Sábados"
            },
            {
                text: "Vie. 15:00 a 20:00h\nSáb. 9:00 a 14:00h"
            }
        ]
    },
    pricing: {
        label: "Precio",
        amount: "3.600€",
        buttonText: "Quiero más información"
    },
    details: {
        title: "Detalles del Máster",
        list: [
            "12 semanas - 120 horas",
            "9 docentes en activo",
            "Certificación al finalizar",
            "Masterclass en directo"
        ]
    }
};
export const faqSectionTexts = {
    title: "Preguntas Frecuentes",
    questions: [
        {
            question: '¿La formación es en directo?',
            answer: 'Sí, hemos ideado nuestro Programa Ingeniero Backend Data Science Python como una formación completamente online pero sin perder la esencia de poder vivirla compartiendo la experiencia en directo con tus compañeros y profesores. Así pues, cada clase será en directo para que puedas preguntar tus dudas a tus profesores y hablar con tus compañeros.'
        },
        {
            question: '¿Cómo puedo acceder al Programa?',
            answer: 'Es tan fácil como completar la solicitud con tus datos y nos pondremos rápidamente en contacto contigo. Agendaremos una entrevista para conocer tus intereses y ayudarte a alcanzar tu objetivo.'
        },
        {
            question: '¿Qué requisitos hacen falta?',
            answer: 'Partirás con una base si tienes conocimiento sobre producto, desarrollo, data, marketing o negocio, pero no son requerimientos obligatorios.'
        },
        {
            question: '¿Por qué elegir este Programa y no otro?',
            answer: 'En Thinkia Training hemos creado un programa 100% online con Masterclass en directo cada viernes y sábado en el que adquirás de la mano de profesionales en activo que tienen experiencia con este rol, y estarás en contacto con compañeros en un punto profesional similar al tuyo, y con las mismas inquietudes. Estamos totalmente comprometidos con tu formación, por eso hemos diseñado un programa adaptado a las necesidades actuales de las empresas con profesores que son profesionales de alto nivel en activo.'
        },
        {
            question: '¿Lo puedo hacer mientras estoy trabajando?',
            answer: 'El horario está adaptado al horario laboral para que puedas compaginar tu trabajo con tu formación. Además, al tratarse de una formación online puedes conectarte desde cualquier lugar a la hora de la Masterclass y pondremos a tu disposición la documentación que aporte cada profesor en nuestra plataforma formativa. Y si algún día no puedes conectarte las sesiones se grabarán para verlas luego.'
        }
    ],    
};

const textContextData = {
    homeAndFormTexts,
    sectionTwoTexts,
    accordionTexts,
    targetAudienceTexts,
    upcomingSectionTexts,
    faqSectionTexts
};

export default textContextData;