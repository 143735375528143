import React from "react";
import { Route, Routes } from "react-router-dom";
// internal
import BackToTop from "../components/BackToTop";
import ContextProvider from "../components/context/ContextProvider";
import { HelmetProvider } from "react-helmet-async";

import Home from "../pages/Home";
import LandingDevOps from "../pages/LandingDevOps";
import LandingAgile from "../pages/LangingAgile";
import LangingThinkia from "../pages/LangingThinkia";
import LandingCyberSecurity from "../pages/LandingCyberSecurity";
import AvisoLegal from "../pages/LandingCyberSecurity/components/common_components/AvisoLegal";
import Privacidad from "../pages/LandingCyberSecurity/components/common_components/Privacidad";
import Cookies from "../pages/LandingCyberSecurity/components/common_components/Cookies";
import LandingIAGenerativa from "src/pages/LandingIAGenerativa";
import LandingBackendPython from "src/pages/LandingBackendPython";
import LandingDataPython from "src/pages/LandingDataPython";

const AppNavigation = () => {
  return (
    <ContextProvider>
      <HelmetProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/training/master-devops" element={<LandingDevOps />} />
            <Route path="/training/master-agile-management" element={<LandingAgile />} />
            <Route path="/training/master-product-management" element={<LangingThinkia />} />
            <Route path="/training/master-ciberseguridad" element={<LandingCyberSecurity />} />
            <Route path="/training/programa-avanzado-ia-generativa" element={<LandingIAGenerativa />} />
            <Route path="/training/programa-ingeniero-data-science-python" element={<LandingDataPython />} />
            <Route path="/training/programa-ingenierio-backend-python" element={<LandingBackendPython />} />
            <Route path="/aviso-legal" element={<AvisoLegal />} />
            <Route path="/cookies" element={<Cookies />} />
            <Route path="/privacidad" element={<Privacidad />} />
          </Routes>
        <BackToTop />
      </HelmetProvider>
    </ContextProvider>
  );
};

export default AppNavigation;
