export const homeAndFormTexts = {
    formSection: {
        name: "Nombre *",
        phone: "Teléfono *",
        email: "Correo *",
        message: "Si tienes dudas escríbenos aquí tu consulta ...",
        rgpdText: "Acepto el tratamiento de datos y políticas de privacidad de Thinkia (Search Engine Business S.L.).*",
        informationButton: "Quiero más información"
    }
};
export const sectionTwoTexts = {
    mainSection: {
        title: {
            firstPart: "Aprende de la mano de destacados profesionales del sector, en activo y con contrastada experiencia docente.",
            secondPart: "Adquiere nuevos conocimientos y las habilidades que demandan las empresas."
        },
        stats: [
            {
                number: "13",
                label: "SEMANAS"
            },
            {
                number: "130",
                label: "HORAS"
            },
            {
                number: "10",
                label: "DOCENTES EXPERTOS"
            }
        ]
    },
    learnSection: {
        title: "¿Qué vas a conseguir?",
        benefits: [
            {
                text: "Aplicar técnicas avanzadas de análisis de datos en SQL y BigQuery, incluyendo análisis geoespacial y Machine Learning en entornos de bases de datos."
            },
            {
                text: "Desarrollar habilidades en el uso de Apache Spark y BigQuery para el procesamiento de grandes volúmenes de datos en entornos distribuidos."
            },
            {
                text: "Crear visualizaciones efectivas y dashboards interactivos en PowerBI para comunicar insights a diversas audiencias."
            },
            {
                text: "Explorar bases de datos relacionales, no relacionales, de grafos y vectoriales para diseñar soluciones de almacenamiento y consulta avanzadas."
            },
            {
                text: "Implementar prácticas DevOps con Bitbucket, Jenkins y Docker para la gestión del ciclo de vida y despliegue de modelos de IA."
            },
            {
                text: "Aprender a desplegar y ajustar modelos generativos y LLM para aplicaciones personalizadas de generación de texto y búsqueda."
            },
            {
                text: "Diseñar arquitecturas basadas en colas y microservicios para la gestión de flujos de trabajo de IA generativa en sistemas distribuidos."
            },
            {
                text: "Usar plataformas como Langchain, Dify y Flowise para el desarrollo rápido de aplicaciones de IA y la conexión de datos con LLMs."
            },
            {
                text: "Configurar infraestructuras en Google Cloud y AWS para el almacenamiento, cómputo y despliegue de soluciones de IA generativa en la nube."
            }
        ]
    }
};

export const accordionTexts = {
    title: "Contenido del Máster",
    modules: [
        {
            number: 'Módulo 01',
            title: 'Fundamentos de Data Science con SQL y BigQuery',
            content: [
                {
                    list: [
                        'Introducción a la Ciencia de Datos aplicada a entornos de bases de datos.',
                        'Consultas SQL avanzadas para el análisis de grandes volúmenes de datos (PostgreSQL y MySQL).',
                        'Uso de BigQuery para Machine Learning y consultas distribuidas.',
                        'Geo Analytics: análisis geoespacial con SQL y BigQuery.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 02',
            title: 'Big Data y Entornos Distribuidos',
            content: [
                {
                    list: [
                        'Introducción a Big Data y su arquitectura.',
                        'Uso de Apache Spark para procesamiento de datos en entornos distribuidos.',
                        'Creación de flujos de trabajo escalables con Spark: RDD, DataFrames y SparkSQL.',
                        'Integración de BigQuery con Spark para análisis de grandes volúmenes de datos.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 03',
            title: 'Visualización y Storytelling con Datos',
            content: [
                {
                    list: [
                        'Introducción a la visualización de datos en PowerBI.',
                        'Creación de dashboards interactivos y análisis visual.',
                        'Data Storytelling: cómo presentar insights de manera efectiva para audiencias técnicas y no técnicas.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 04',
            title: 'Bases de Datos Avanzadas',
            content: [
                {
                    list: [
                        'Bases de datos relacionales: diseño y optimización de consultas en PostgreSQL, MySQL y SQL Server.',
                        'Bases de datos no relacionales: almacenamiento y consulta de datos flexibles en MongoDB.',
                        'Bases de datos en grafos: análisis de redes y relaciones complejas con Neo4j.',
                        'Bases de datos vectoriales para IA generativa: uso de Chroma y Amazon Neptune para búsqueda semántica y almacenamiento eficiente de embeddings vectoriales.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 05',
            title: 'DevOps para Data Science e IA',
            content: [
                {
                    list: [
                        'Bitbucket y Git Flow: gestión avanzada del ciclo de vida del desarrollo.',
                        'Automatización con Jenkins: pipelines CI/CD para modelos de IA y aplicaciones basadas en datos.',
                        'Docker: despliegue de entornos de IA generativa.',
                        'Calidad del código y seguridad con Sonarqube.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 06',
            title: 'Modelos Generativos y Large Language Models (LLM)',
            content: [
                {
                    list: [
                        'Introducción a Ollama y AnythingLLM: despliegue y uso de modelos generativos en producción.',
                        'Aplicaciones de LLM en tareas de generación de texto, resumen y búsqueda.',
                        'Fine-tuning y adaptación de modelos generativos para aplicaciones personalizadas.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 07',
            title: 'Arquitectura de Sistemas para IA Generativa',
            content: [
                {
                    list: [
                        'Arquitectura basada en colas: integración de RabbitMQ, Redis y Google Pub/Sub para la gestión eficiente de tareas asíncronas.',
                        'Microservicios con colas: diseño de sistemas distribuidos basados en eventos para IA generativa.',
                        'Event-Driven Architecture (EDA): procesamiento de eventos en tiempo real.',
                        'Kafka y Pub/Sub para manejar grandes volúmenes de eventos.',
                        'Gestión de tareas asíncronas en entornos distribuidos.',
                        'Modelo de agentes: uso de agentes autónomos en flujos de trabajo de IA, con capacidad de tomar decisiones basadas en datos.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 08',
            title: 'Herramientas y Frameworks para IA Generativa',
            content: [
                {
                    list: [
                        'Introducción a Dify y Flowise: plataformas para el desarrollo y despliegue rápido de aplicaciones de IA.',
                        'Langchain: construcción de flujos de trabajo avanzados para modelos de lenguaje.',
                        'Uso de Langchain para conectar datos estructurados y no estructurados con LLMs.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 09',
            title: 'Infraestructura Cloud para IA Generativa',
            content: [
                {
                    list: [
                        'Diferencias entre on-premise, cloud y virtualización (Google Cloud, AWS, Azure).',
                        'Google Cloud como infraestructura primaria: uso de servicios de almacenamiento, cómputo y BigQuery.',
                        'Introducción a AWS como infraestructura secundaria: uso de S3, Lambda y EC2 para el despliegue y escalabilidad de soluciones de IA.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 10',
            title: 'Proyecto Final',
            content: [
                {
                    description: 'Integración de conocimientos en un desafío práctico para desarrollar un proyecto sobre Data Science o IA generativa, aplicando técnicas de análisis, visualización y despliegue en infraestructura cloud.'
                }
            ]
        }
    ]
};

export const targetAudienceTexts = {
    mainTitle: "¿A quién va dirigido?",
    prerequisites: {
        description: "Enfocado para perfiles con conocimientos en Data Science que quieran dar el salto a IA Generativa.",
    },
    features: [
        {
            iconAlt: "Icono casa",
            text: "Desde Casa"
        },
        {
            iconAlt: "Icono Másterclass",
            text: "Másterclasses en directo"
        },
        {
            iconAlt: "Icono expertos",
            text: "Profesionales expertos en activo"
        }
    ]
};
export const upcomingSectionTexts = {
    mainTitle: "Próximas Convocatorias",
    edition: {
        items: [
            {
                text: "I Edición"
            },
            {
                text: "7 Marzo 2025"
            },
            {
                text: "Masterclass en directo\nViernes y Sábados"
            },
            {
                text: "Vie. 15:00 a 20:00h\nSáb. 9:00 a 14:00h"
            }
        ]
    },
    pricing: {
        label: "Precio",
        amount: "4.900€",
        buttonText: "Quiero más información"
    },
    details: {
        title: "Detalles del Máster",
        list: [
            "13 semanas - 130 horas",
            "10 docentes en activo",
            "Certificación al finalizar",
            "Masterclass en directo"
        ]
    }
};
export const faqSectionTexts = {
    title: "Preguntas Frecuentes",
    questions: [
        {
            question: '¿La formación es en directo?',
            answer: 'Sí, hemos ideado nuestro Programa Avanzado de Ingeniería en IA Generativa como una formación completamente online pero sin perder la esencia de poder vivirla compartiendo la experiencia en directo con tus compañeros y profesores. Así pues, cada clase será en directo para que puedas preguntar tus dudas a tus profesores y hablar con tus compañeros.'
        },
        {
            question: '¿Cómo puedo acceder al Programa?',
            answer: 'Es tan fácil como completar la solicitud con tus datos y nos pondremos rápidamente en contacto contigo. Agendaremos una entrevista para conocer tus intereses y ayudarte a alcanzar tu objetivo.'
        },
        {
            question: '¿Qué requisitos hacen falta?',
            answer: 'Partirás con una base si tienes conocimiento sobre producto, desarrollo, data, marketing o negocio, pero no son requerimientos obligatorios.'
        },
        {
            question: '¿Por qué elegir este Programa y no otro?',
            answer: 'En Thinkia Training hemos creado un programa 100% online con Masterclass en directo cada viernes y sábado en el que adquirirás de la mano de profesionales en activo que tienen experiencia con este rol, y estarás en contacto con compañeros en un punto profesional similar al tuyo, y con las mismas inquietudes. Estamos totalmente comprometidos con tu formación, por eso hemos diseñado un programa adaptado a las necesidades actuales de las empresas con profesores que son profesionales de alto nivel en activo.'
        },
        {
            question: '¿Lo puedo hacer mientras estoy trabajando?',
            answer: 'El horario está adaptado al horario laboral para que puedas compaginar tu trabajo con tu formación. Además, al tratarse de una formación online puedes conectarte desde cualquier lugar a la hora de la Masterclass y pondremos a tu disposición la documentación que aporte cada profesor en nuestra plataforma formativa. Y si algún día no puedes conectarte las sesiones se grabarán para verlas luego o poder repasar la clase con el experto.'
        }
    ]
};

const textContextData = {
    homeAndFormTexts,
    sectionTwoTexts,
    accordionTexts,
    targetAudienceTexts,
    upcomingSectionTexts,
    faqSectionTexts
};

export default textContextData;